.words {
    color: white;
    text-align: center;
}

.logo {
    margin-top: 20px;
    fill: white;
    width: 225px;
    padding-bottom: 50px;
}