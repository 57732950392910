.button {
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  line-height: 1.47;
  height: 100%;
  width: 95px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;
  box-shadow: 1px 1px 3px black;
  border: 1px solid transparent;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
