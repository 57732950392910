.header {
  margin-top: 50px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.logo-auth {
  width: 200px;
}


