.progress-bar {
    position: relative;
    height: 10px; 
    width: 100px; 
    margin: 15px;
    border-radius: 50px; 
    border: 1px solid #fff; 
}

.filler {
    background: #1DA598;
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in; 
}