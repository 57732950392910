@keyframes rollout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 242px;
  justify-content: center;
  animation: rollout 0.7s;
}

.screen__title {
  margin: 0;
  margin-bottom: 5px;
  color: white;
}
