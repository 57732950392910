.header-progress {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
  }
  
  .logo-header{
    margin-left: 20px;
    width: 100px; 
    flex: 1; 
    padding-right:50px;
  }

  
  .progress-wrapper {
    margin: auto;
    text-align: center;
  }

  
  .progress-bar {
    width: 100px;
    margin-left:auto;
    margin-right:auto;
  }
  
  .progress-number {
      margin-left:auto;
      margin-top:10px;
      margin-bottom:-8px;
      margin-left:auto;
  }