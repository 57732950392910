.question {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}

.question__responses {
  display: flex;
  flex-direction: column;
}

@keyframes rollout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.question__rollout {
  animation: rollout 0.7s;
}

.question__response {
  margin-top: 10px;
}

.question__submit {
  display: inline-block;
  border-radius: 2px;
  cursor: pointer;
  line-height: 1.47;
  height: 100%;
  min-width: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 15px;
  border: 1px solid transparent;
  margin-top: 10px;
}

.question__actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.question__submitting {
  flex: 1;
  text-align: center;
  margin-top: 10px;
}

.question__action {
  margin-right: 10px;
}

/* Changes only apply to pulse */
/* ============================ */
.question__pulse {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}

.question__span__pulse {
  font-size: 2rem;
}

.question__responses__pulse {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.question__response__pulse{
  margin-top: 15px;
}

.question__response__pulse label{
  padding-left: 1rem;
}

.question__actions__pulse {
  margin-top: 2rem;
}
/* ============================ */