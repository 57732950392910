.textInput {
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  line-height: 1.47;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 15px;
  border: 1px solid black;
  margin-top: 10px;
}
